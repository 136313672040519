import "./section-services.css"
import CardServices from "./../atomic/molecules/card-sercice/CardServices"

const services = [
  {
    titleCard: "1. Diseño de interfaces",
    imagen: "assets/images/diseño.webp",
    description: "Llevamos sus ideas a prototipos. Feedback inmediato",
  },
  {
    titleCard: "2. Desarrollo de software",
    imagen: "assets/images/dev.webp",
    description:
      "Convertiremos sus diseños en aplicaciones. Vamos a producción!",
  },
  {
    titleCard: "3. Soporte",
    imagen: "assets/images/consulta.webp",
    description:
      "Atención al cliente y mejora continua. Mejoremos la experiencia de usuario",
  },
]

const SectionServices = () => {
  return (
    <div className="section-2">
      <section className="services section" id="Servicios">
        <h2 className="services-title">Servicios</h2>
        <div className="services-group">
          {services.map((service) => (
            <CardServices
              key={service.titleCard}
              titleCard={service.titleCard}
              imagen={service.imagen}
              description={service.description}
            />
          ))}
        </div>
      </section>
    </div>
  )
}

export default SectionServices
