import "./logo-type.css"

const LogoType = () => {
  return (
    <div className="logo-type">
      <img className="logo" src="../assets/images/logo.svg" alt="logo" />
      <img
        className="name-logo"
        src="../assets/images/nombre-medine.svg"
        alt="logo"
      />
    </div>
  )
}

export default LogoType
