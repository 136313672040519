import "./footer.css"
import email from "./img/email-icon.svg"

const Footer = () => {
  return (
    <>
      <footer className="footer bg-degrade-blue">
        <p>
          © 2022 - MEDINE Desarrolladora y consultora de software | Venezuela
          (GMT-4:00)
        </p>
        <p className="p-email">
          <img src={email} alt="" />
          <a className="footer-link" href="mailto:customers@medine.tech">
            customers@medine.tech
          </a>
        </p>
      </footer>
    </>
  )
}

export default Footer
