import SectionPresentacion from "./components/section-presentation/SectionPresentation"
import SectionServices from "./components/section-services/SectionServices"
import Footer from "./components/footer/Footer"

const App = () => {
  return (
    <>
      <main className="M-main">
        <SectionPresentacion />
        <SectionServices />
        <Footer />
      </main>
    </>
  )
}

export default App
