import "./card-services.css"

interface cardServicesProps {
  titleCard?: string
  imagen?: string
  description?: string
}

const CardServices = ({
  titleCard = "Sin titulo",
  imagen = "favicon.svg",
  description = "No hay descripcion del servicio",
}: cardServicesProps) => {
  return (
    <div className="service">
      <img src={imagen} loading="lazy" alt="" />
      <h3>{titleCard}</h3>
      <p>{description}</p>
    </div>
  )
}

export default CardServices
