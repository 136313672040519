import "./section-presentation.css"
import LogoType from "../shared/LogoType"
const SectionPresentacion = () => {
  return (
    <section className="presentation" id="Presentation">
      <div>
        <LogoType />
        <p className="presentation-p">
          Calidad en nuestros servicios. Tecnología para facilitar su día.
        </p>
      </div>
    </section>
  )
}

export default SectionPresentacion
